import { project } from '@/lib/project';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { MdEmail, MdPhone, MdPlace } from 'react-icons/md';
import { i18nRoute } from 'routes';
import RatingStars from '../company/RatingStars';
import TransportTypeBadge from '../ui/TransportTypeBadge';

export function Amenities({ stationId, amenities }) {
	return (
		<ul
			role="list"
			className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
		>
			{amenities.map((amenity) => (
				<li key={`${stationId}_${amenity}`} className="flex gap-x-3 capitalize">
					<CheckIcon
						className="h-6 w-5 flex-none text-blue-600"
						aria-hidden="true"
					/>
					{amenity}
				</li>
			))}
		</ul>
	);
}

export function StationAmentiesCard({ title, subtitle, station }) {
	const { t } = useTranslation(['common']);
	return (
		<div key={station.id} className="rounded-3xl ring-1 ring-gray-300">
			<div className="p-6 sm:p-10 lg:flex-auto">
				{title && (
					<h3 className="text-2xl font-bold tracking-tight text-gray-900">
						{title}
					</h3>
				)}
				{subtitle && (
					<p className="mt-4 text-sm leading-6 text-gray-600">{subtitle}</p>
				)}
				{station.amenities &&
					Object.keys(station.amenities)
						.sort()
						.map((key) => (
							<div key={`${station.id}_${key}`}>
								<div className="mt-10 flex items-center gap-x-4">
									<h4 className="flex-none text-sm font-semibold capitalize leading-6 text-blue-600">
										{t(`common:stations.${key}`)}
									</h4>
									<div className="h-px flex-auto bg-gray-100" />
								</div>
								<Amenities
									stationId={station.id}
									amenities={station.amenities[key]}
								/>
							</div>
						))}
			</div>
		</div>
	);
}

export function StationMap({ name, latitude, longitude, hasLink = false }) {
	return hasLink ? (
		<a
			className="block"
			href={`https://www.google.com/maps?q=${latitude},${longitude}`}
			rel="noreferrer noopener"
			target="_blank"
			aria-describedby={name}
		>
			<Image
				width={200}
				height={200}
				alt={`${name} map`}
				title={`${name} map`}
				className="h-auto w-full cursor-pointer rounded-2xl object-cover hover:bg-white hover:opacity-80"
				src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=400x400&maptype=roadmap&scale=2&markers=color:red|label:${name}|${latitude},${longitude}&key=${project.googleMapsApiKey}`}
			/>
		</a>
	) : (
		<Image
			width={200}
			height={200}
			alt={`${name} map`}
			title={`${name} map`}
			className="h-auto w-full rounded-2xl object-cover"
			src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=400x400&maptype=roadmap&scale=2&markers=color:red|label:${name}|${latitude},${longitude}&key=${project.googleMapsApiKey}`}
		/>
	);
}

export function StationCardContent({
	station,
	hasMap = true,
	includeMapInside = false,
}) {
	const { t, i18n } = useTranslation(['common']);
	return (
		<>
			<div className="relative space-y-4 overflow-hidden lg:flex-auto">
				<div>
					<Link
						className="absolute inset-0"
						prefetch={false}
						aria-labelledby={station.id}
						title={station.name}
						href={i18nRoute(`/stations/[stationId]`, i18n.language, {
							stationId: station.slug,
						})}
					>
						<span className="sr-only">
							{t('common:relatedRoutes.bus_tickets_to', {
								locationName: station.name,
							})}
						</span>
					</Link>
					<h3 className="cursor-pointer text-xl font-bold tracking-tight text-blue-600 hover:text-blue-700 lg:text-2xl">
						<span className="sr-only">
							{t('common:relatedRoutes.bus_tickets_to', { locationName: '' })}
						</span>
						{station.name}
					</h3>
					<div className="flex flex-wrap items-center gap-x-2">
						<RatingStars rating={station.rating} />
						<TransportTypeBadge
							items={station.transportTypes || station.transport_types}
						/>
					</div>
				</div>
				{hasMap && includeMapInside && (
					<StationMap
						latitude={station.latitude || station?.coordinates.lat}
						longitude={station.longitude || station?.coordinates.lon}
						name={station.name}
					/>
				)}
				<p className="line-clamp-6 mb-4 whitespace-normal text-sm leading-7 text-gray-600">
					{station.description}
				</p>

				<div className="space-y-2 text-sm text-gray-600">
					{station.address && (
						<div className="flex items-center space-x-2">
							<MdPlace className="h-5 w-5 shrink-0 text-gray-500" />
							<span className="">{station.address}</span>
						</div>
					)}
					{station.phone && (
						<div className="flex items-center space-x-2">
							<MdPhone className="h-5 w-5 shrink-0 text-gray-500" />
							<span className="">{station.phone}</span>
						</div>
					)}
					{/* {station.site && (
						<div className="flex items-center space-x-2">
							<MdWeb className="h-5 w-5 shrink-0 text-gray-500" />
							<span className="truncate">{station.site}</span>
						</div>
					)} */}
					{station.email && (
						<div className="flex items-center space-x-2 ">
							<MdEmail className="h-5 w-5 text-blue-600" />
							<span className="truncate">{station.site}</span>
						</div>
					)}
				</div>
			</div>
			{hasMap && !includeMapInside && (
				<div className="mx-auto shrink-0 lg:w-1/3">
					<StationMap
						latitude={station.latitude || station?.coordinates.lat}
						longitude={station.longitude || station?.coordinates.lon}
						name={station.name}
					/>
				</div>
			)}
		</>
	);
}

export function StationsCarrousel({ stations, title }) {
	return (
		<section className="mx-auto max-w-7xl py-8 lg:space-y-8">
			<div className="space-y-4 px-4 lg:px-8">
				<h2 className="text-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
					{title}
				</h2>
				<div className="scrollbar-hide [&::-webkit-scrollbar]:hidden flex gap-x-4 overflow-auto whitespace-nowrap px-1 py-4 [-ms-overflow-style:'none'] [scrollbar-width:'none'] lg:gap-x-8">
					{stations.map((station) => (
						<div
							key={station.id}
							className="w-[85%] max-w-[330px] shrink-0 overflow-hidden rounded-3xl bg-white  p-4 ring-1 ring-gray-300 hover:shadow-lg"
						>
							<StationCardContent station={station} hasMap includeMapInside />
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default function StationCard({ station, includeMapInside = false }) {
	return (
		<div className="mx-auto flex max-w-2xl flex-col items-start gap-x-12 gap-y-8 rounded-3xl p-4 ring-1 ring-gray-300 lg:mx-0 lg:max-w-none lg:flex-row  lg:p-8">
			<StationCardContent
				station={station}
				includeMapInside={includeMapInside}
			/>
		</div>
	);
}
