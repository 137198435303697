import Image from 'next/image';

export default function CompanyLogo(props) {
	const { companyId, ...imgProps } = props;
	if (!companyId) return null;
	if (!imgProps.alt) imgProps.alt = `${companyId}`;
	const altReplacement = 'Amtrak - ';
	const imageId = companyId.includes('amtrak') ? 'amtrak' : companyId;

	return (
		<div className="relative">
			<Image
				onError={(e) => {
					e.target.classList.add('hidden');
					e.target.nextElementSibling.classList.remove('hidden');
				}}
				alt={imgProps.alt}
				src={`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET}/companies/resized/${imageId}.webp`}
				{...imgProps}
			/>
			<div className="hidden max-w-[100px] flex-col truncate text-sm font-bold uppercase leading-none text-gray-700 lg:max-w-[150px]">
				{imgProps.alt.split('#').map((n) => (
					<span key={n} className="truncate whitespace-nowrap">
						{n}
					</span>
				))}
			</div>
			{imgProps.alt.includes(altReplacement) && (
				<span className="text-nowrap text-right text-xs text-gray-500">
					{imgProps.alt.replace(altReplacement, '')}
				</span>
			)}
		</div>
	);
}
