import { project } from '@/lib/project';
import { useTranslation } from 'next-i18next';

export default function TestimonialSection() {
	const { t } = useTranslation(['home']);
	return (
		<section className="overflow-hidden bg-blue-50 py-12 md:py-20 lg:py-24">
			<div className="relative mx-auto max-w-7xl px-4 lg:px-8">
				<div className="relative">
					<blockquote className="mt-10">
						<div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
							<p>
								&ldquo;
								{t(`home:sections.testimonials.comment`, {
									project: project.name,
								})}
								&rdquo;
							</p>
						</div>
						<footer className="mt-8">
							<div className="md:flex md:items-center md:justify-center">
								<div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
									<div className="text-base font-medium text-gray-900">
										{t(`home:sections.testimonials.author`)}
									</div>

									<svg
										className="mx-1 hidden h-5 w-5 text-blue-600 md:block"
										fill="currentColor"
										viewBox="0 0 20 20"
									>
										<path d="M11 0h3L9 20H6l5-20z" />
									</svg>

									<div className="text-base font-medium text-gray-600">
										{t(`home:sections.testimonials.city`)}
									</div>
								</div>
							</div>
						</footer>
					</blockquote>
				</div>
			</div>
		</section>
	);
}
