import { StarIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

export default function RatingStars({ rating, compact }) {
	return (
		<div className="flex items-center">
			{[1, 2, 3, 4, 5].map((index) => (
				<StarIcon
					key={index}
					className={clsx(
						rating >= index ? 'text-yellow-400' : 'text-gray-300',
						compact ? 'size-4' : 'size-5',
						'shrink-0'
					)}
					aria-hidden="true"
				/>
			))}
		</div>
	);
}
