import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { i18nRoute } from 'routes';

export default function DestinationLink({
	className,
	children,
	destination,
	prefetch = false,
}) {
	const { i18n } = useTranslation('common');

	const href = i18nRoute(`/destinations/[destinationId]`, i18n.language, {
		destinationId: destination.slug,
	});

	return (
		<Link
			className={clsx(className)}
			title={destination.longName}
			href={href}
			prefetch={prefetch}
		>
			{children ? children : destination.longName}
		</Link>
	);
}
